import { HashScroll } from "react-hash-scroll";

export const About = () => {
  return (
    <HashScroll hash="#services">
      <div className="flex bg-handyman bg-cover min-h-screen text-white justify-evenly items-center">
        <div>
          <div className="text-4xl mb-2">Services Include</div>
          <ul className="font-bold pl-2 gap-2 text-xl">
            <li>Blind installation</li>
            <li>Door and trim installation & repairs</li>
            <li>Drywall repairs</li>
            <li>Electrical work</li>
            <li>Exterior & interior painting</li>
            <li>Fan installation & repairs</li>
            <li>Flooring installation & repairs</li>
            <li>Furniture assembly</li>
            <li>General repairs</li>
            <li>Gutter cleaning</li>
            <li>Home maintenance & repairs</li>
            <li>Home remodeling</li>
            <li>Moving assistance</li>
            <li>Plumbing fixture installation & repairs</li>
            <li>Tile work installation & replacement</li>
            <li>TV mounting</li>
            <li>Shelving installation & repairs</li>
            <li>Water fixture repairs</li>
            <li>Weather sealing</li>
          </ul>
        </div>
        <div></div>
      </div>
    </HashScroll>
  );
};
