import { HashScroll } from "react-hash-scroll";
import fb from "../images/fb.png";
import gmail from "../images/gmail.png";
import { useState } from "react";

export const Contact = () => {
  const [name, setName] = useState("");
  const [body, setBody] = useState("");

  return (
    <HashScroll hash="#contact">
      <div className="flex flex-col items-center gap-4">
        <p className="text-3xl mt-4">Contact Us</p>

        <div className="flex flex-col w-1/2">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            className="p-4 bg-gray-100 border-gray-400 border rounded w-full"
          />
          <label htmlFor="body">Services Needed:</label>
          <textarea
            id="body"
            name="body"
            value={body}
            onChange={(event) => setBody(event.target.value)}
            className="p-4 bg-gray-100 border-gray-400 border rounded w-full"
          />
          <a
            href={
              "mailto:anyoddjobsllc@gmail.com?subject=AnyOddJob Service Inquiry - " +
              name +
              "&body=" +
              body
            }
          >
            <button
              type="submit"
              value="Submit"
              className="border-blue-500 border rounded text-blue-500 p-4 w-40 m-auto mt-4"
            >
              Send Email
            </button>
          </a>
        </div>

        <a
          className="text-4xl font-bold mt-2 text-blue-600"
          href="tel:8644045339"
        >
          864-404-5339
        </a>

        <a
          className="text-3xl font-bold mt-2 text-blue-600"
          href="mailTo:anyoddjobsllc@gmail.com"
        >
          anyoddjobsllc@gmail.com
        </a>

        <div className="flex gap-4 items-center">
          <a
            className="w-10"
            href="https://www.facebook.com/profile.php?id=100093106625687"
            target="_"
          >
            <img src={fb} />
          </a>
          <a className="w-10" href="mailTo:anyoddjobsllc@gmail.com">
            <img src={gmail} width={40} />
          </a>
        </div>

        <p>© 2022 Copyright AnyOddJobLLC</p>
      </div>
    </HashScroll>
  );
};
