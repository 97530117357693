import "./App.css";
import TopNav from "./top-nav/TopNav";
import { About } from "./about/About";
import { Contact } from "./contact/Contact";
import { Home } from "./home/Home";

function App() {
  return (
    <>
      <TopNav />
      <Home />
      <About />
      <Contact />
    </>
  );
}

export default App;
