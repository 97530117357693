import { HashScroll } from "react-hash-scroll";
import logo from "../images/logo.png";

export const Home = () => {
  return (
    <HashScroll hash="#home">
      <div className="flex flex-col sm:flex-row min-h-screen items-center justify-evenly m-8 gap-8">
        <div className="flex flex-col items-center">
          <img src={logo} alt="logo" />
          <p className="font-brush text-5xl">"No job too small"</p>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-5xl">Need a job done around the house?</p>
          <p>Look no further. </p>
          <p className="text-lg max-w-3xl">
            Anyoddjob is an owner operated local business dedicated to providing
            expedient handyman services around the upstate. Our mission is to
            show up for any job on your schedule, no matter how small.
          </p>
        </div>
      </div>
    </HashScroll>
  );
};
